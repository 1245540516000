import { useEffect } from 'react'
import { navigate } from 'gatsby'

// redirect to the homepage
export default function redir() {
  useEffect(() => {
    navigate('/', { replace: true })
  }, [])

  return null
}
